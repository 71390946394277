import React from 'react';
import {css} from 'emotion';

/* global tw */
export default function success(props) {

    return (
        <div className={css`${tw`m-8`}`}>
        <h1 className={css`${tw`font-brand`}`}>Thank you for your intrest!</h1>
        <p className={css`${tw`mt-4 font-normal font-brand text-xl lg:text-2xl `}`}>Our support team will get back to you as soon as possible.</p>
        </div>
    )
}
