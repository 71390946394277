import React from 'react';
import {css} from 'emotion';

/* global tw */
export default function partnerHome(props) {

    return (
        <div className={css`${tw`m-8`}`}>
        <h1 className={css`${tw`font-brand`}`}>Hi there!</h1>
        <p className={css`${tw`mt-4 font-normal font-brand text-xl lg:text-xl `}`}>We’re excited to know that you want to partner with us.</p>
        <p className={css`${tw`mt-4 font-normal font-brand text-xl lg:text-xl`}`}>You’ll have to fill necessary details in the form, then you’ll submit it to be reviewed by Zyppys.</p>
        <div className={css`${tw`mt-6 h-12 text-center py-3 font-bold text-white w-1/2 lg:w-1/4 rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%);  &:hover{ transform: scale(0.98); }`}
        onClick={props.optionsClicked}
        >Let’s Go</div>
        </div>
    )
}
