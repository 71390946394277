import React, { Component } from 'react'
import {css} from 'emotion';
import axios from 'axios';
import { navigate } from 'gatsby';
import DropDown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from "moment";

//Components
import { urls as urls } from '../../api/urls';
import Success from '../partnerComponent/success';

/* global tw */
class restaurants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurantName: '',
            location:'',
            city: '',
            restaurantType: 'Family-Friendly',
            name: '',
            email: '',
            phone: '',
            countryCode: "+91",
            otpSent:false,
            otp: '',
            valid:false,
            otpError:'',
            nameError:'',
            emailError:'',
            phoneError:'',
            success:false,
            nameValid:false,
            emailValid:false,
            phoneValid:false,
            restaurantNameValid:false,
            locationValid:false,
            cityValid:false,
            otpValid:false,
            buttonText:'Next',
        }
        this.handleValidation = this.handleValidation.bind(this);
        this.sendOtp = this.sendOtp.bind(this);
        this.authenticateOtp = this.authenticateOtp.bind(this);
    }

    sendOtp = () => {
        console.log(this.state);
        let self = this;
        self.setState({otp:'',otpError:''})
        if(this.state.restaurantType && this.state.nameValid && this.state.emailValid && this.state.phoneValid && this.state.restaurantNameValid && this.state.cityValid && this.state.locationValid){
        let url = urls.sendOtpUrl;
        let otpInput = {
            "user": {
                "name": this.state.name,
                "emailId": this.state.email,
                "mobileNo": Number(this.state.phone),
                "countryCode": Number(this.state.countryCode),
                "fcmId": ' ',
            },
            "loginType": "CUSTOMER"
        }
        axios.post(url, otpInput)
            .then(function (response) {
                
                console.log(response);
                self.setState({otpSent:true, buttonText: 'Submit'});
            })
            .catch(error => { ; console.log(error) })
        }
        else{
            alert("Invalid Details");
        }
    }

    authenticateOtp = () => {
        this.setState({ buttonText: 'Submitting...' });
        let self = this;
        
        let url = urls.authOtpUrl;
        let authinput = {
            "otp": this.state.otp,
            "loginType": "CUSTOMER"
        }
        axios.post(url, authinput)
            .then(function (response) {
                
                // let self = this;
                if (response.data.statusCD === 200) {
                    let emailUrl = urls.sendEmail;
                    let data = {
                        "name": self.state.name,
                        "email": self.state.email,
                        "phone": Number(self.state.phone),
                        "restaurantName": self.state.restaurantName,
                        "location": self.state.location,
                        "city": self.state.city,
                        "restaurantType": typeof self.state.restaurantType.value === 'undefined' ? self.state.restaurantType : self.state.restaurantType.value,
                        "type": "Restaurant",
                        "date": moment().format('DD-MM-YYYY HH:mm:ss'),
                    }
                    console.log(data);
                    axios.post(emailUrl, data)
                        .then(function (emailResponse) {
                            console.log(emailResponse);
                            if (emailResponse.status === 200) {
                                // alert("Thank you for your intrest! Our support team will get back to you ASAP.");
                                // window.scrollTo(0, 0);
                                // navigate('/');
                                self.setState({ success: true });
                            }
                            else if (emailResponse.status === 500) {
                                self.setState({ otpValid: false, otpError: 'Invalid OTP', buttonText: 'Submit' });

                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
                else if (response.data.statusCD === 401) {
                    self.setState({ otpValid:false, otpError: 'Invalid OTP', buttonText: 'Submit' });

                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    handleValidation(type){
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if(type === 'name'){
            this.state.name !== '' ?  this.setState({ nameValid: true, nameError: '' }) : this.setState({ nameValid: false, nameError: 'Required'});
        }
        else if(type === 'email'){
            (reg.test(this.state.email)) ? this.setState({ emailValid: true, emailError: ''}) : this.setState({ emailValid: false, emailError: 'Not a valid Email'})
        }
        else if(type === 'phone'){
            this.state.phone.length === 10 ? this.setState({ phoneValid: true, phoneError: '' }) : this.setState({ phoneValid: false, phoneError: 'Not a valid Number'})
        }
        else if(type === 'rName'){
            this.state.restaurantName !== '' ?  this.setState({ restaurantNameValid: true, restaurantNameError: '' }) : this.setState({ restaurantNameValid: false, restaurantNameError: 'Required'});
        }
        else if(type === 'city'){
            this.state.city !== '' ?  this.setState({ cityValid: true, cityError: '' }) : this.setState({ cityValid: false, cityError: 'Required'});
        }
        else if(type === 'location'){
            this.state.location !== '' ?  this.setState({ locationValid: true, locationError: '' }) : this.setState({ locationValid: false, locationError: 'Required'});
        }
    }

    render() {
        const restaurantOptions = [
            { value: 'Family-Friendly', label: 'Family-Friendly' },
            { value: 'Casual', label: 'Casual' },
            { value: 'Vegetarian', label: 'Vegetarian' },
            { value: 'South Indian', label: 'South Indian' },
            { value: 'North Indian', label: 'North Indian' },
            { value: 'Breakfast', label: 'Breakfast' },
            { value: 'Cafe', label: 'Cafe' },
            { value: 'Fast Food', label: 'Fast Food' },
          ];
        return (
            <div className={css`${tw`mt-32 mb-8`}`}>
            <div className={css`${tw`absolute m-8  cursor-pointer`}; left:0; top:0; &:hover{  transform: scale(1.2);`}
            onClick={this.props.optionsClicked}>
            <img src={require('../../assets/img/left-arrow.png')} height='40' width='40'/>
            </div>
            {!this.state.otpSent && !this.state.success && <span>
                <p className={css`${tw`p-2 font-brand text-xl lg:text-2xl font-semibold text-grey`}`}>Please enter details below</p>
                <input
                    type='text'
                    value={this.state.restaurantName}
                    onChange={(event) => { this.setState({ restaurantName: event.target.value, restaurantNameError:'', restaurantNameValid: true }) }}
                    onBlur={()=>{ this.handleValidation('rName') }}
                    placeholder={'Restaurant Name?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4`}`}
                />
                {!this.state.restaurantNameValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.restaurantNameError}</p>}
                <input
                    type='text'
                    value={this.state.location}
                    onChange={(event) => { this.setState({ location: event.target.value, locationError: '', locationValid: true}) }}
                    onBlur={()=>{ this.handleValidation('location') }}
                    placeholder={'Restaurant`s Location?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4 mt-4`}`}
                />
                {!this.state.locationValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.locationError}</p>}
                <input
                    type='text'
                    value={this.state.city}
                    onChange={(event) => { this.setState({ city: event.target.value,  cityError:'', cityValid: true}) }}
                    onBlur={()=>{ this.handleValidation('city') }}
                    placeholder={'City Name?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4 mt-4`}`}
                />
                {!this.state.cityValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.cityError}</p>}
                <p className={css`${tw`p-2 font-semibold font-brand text-blackGrey text-xl lg:text-2xl`}`}>Restaurant Type</p>
                <DropDown options={restaurantOptions} onChange={(event)=>{this.setState({ restaurantType: event})}} value={this.state.restaurantType.label} placeholder="Select an option" className={css`${tw`font-medium text-xl lg:text-2xl`}; width: 110%`}/>
                <input
                    type='text'
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value, nameError:'', nameValid: true }) }}
                    onBlur={()=>{ this.handleValidation('name') }}
                    placeholder={'Contact Name?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4 mt-4`}`}
                />
                {!this.state.nameValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.nameError}</p>}
                <input
                    type='text'
                    value={this.state.email}
                    onChange={(event) => { this.setState({ email: event.target.value, emailError:'', emailValid: true }) }}
                    onBlur={()=>{ this.handleValidation('email') }}
                    placeholder={'Contact Email?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4 mt-4`}`}
                />
                {!this.state.emailValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.emailError}</p>}
                <input
                    type='number'
                    value={this.state.phone}
                    onChange={(event) => { this.setState({ phone: event.target.value, phoneError:'', phoneValid: true }) }}
                    onBlur={()=>{ this.handleValidation('phone') }}
                    placeholder={'Contact Phone?'}
                    className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4 mt-4`}`}
                />
                {!this.state.phoneValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.phoneError}</p>}
                <div className={css`${tw`block mt-4 h-12 text-center py-3 font-bold text-white w-1/2 rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); &:hover{  transform: scale(0.98); }`}
                    onClick={() => { this.sendOtp() }}
                >{this.state.buttonText}</div>
                </span>}
                {this.state.otpSent && !this.state.success && <span>
                    <p className={css`${tw`p-2 font-brand text-xl font-semibold text-grey`}`}>Please enter OTP</p>
                    <input
                        type='number'
                        value={this.state.otp}
                        onChange={(event) => { this.setState({ otp: event.target.value, otpError: '', otpValid: true  }) }}
                        placeholder={'Enter OTP'}
                        className={css`${tw`block rounded-lg font-semibold font-brand text-lg lg:text-2xl w-full border-none appearance-none shadow-none outline-none bg-grey-light px-4 py-4`}`}
                    />
                    {!this.state.otpValid && <p className={css`${tw`font-brand text-red`}`}>{this.state.otpError}</p>}
                    <div className={css`${tw`inline-block mt-4 h-12 text-center py-3 font-bold text-white w-1/2 rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); &:hover{  transform: scale(0.98); }`}
                        onClick={() => { this.authenticateOtp() }}
                    >{this.state.buttonText}</div>
                    <div className={css`${tw`inline-block ml-1 lg:ml-4 text-blue text-center py-2 cursor-pointer`}`}
                        onClick={() => { this.setState({otpSent:false, buttonText: 'Next'}) }}
                    >Change Number</div>
                </span>}
                {this.state.success && <Success/>}
            </div>
        )
    }
}

export default restaurants;