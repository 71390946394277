import React from 'react'
import {css} from 'emotion';

/* global tw */
export default function options(props) {
    return (
        <div className={css`${tw``}`}>
        <div className={css`${tw`absolute m-8 cursor-pointer`}; left:0; top:0; &:hover{  transform: scale(1.2);`}
            onClick={props.homeClicked}>
            <img src={require('../../assets/img/left-arrow.png')} height='40' width='40'/>
            </div>
        <div className={css`${tw`mt-4 h-16 text-center py-5 font-bold text-white w-64 rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%);  &:hover{ transform: scale(0.98); }`}
        onClick={props.attachVehicleClicked}
        >Attach Vehicle</div>
        <div className={css`${tw`mt-4 h-16 text-center py-5 font-bold text-white rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); &:hover{ transform: scale(0.98); }`}
        onClick={props.rentalServicesClicked}
        >Car Rentals</div>
        {/* <div className={css`${tw`mt-4 h-16 text-center py-5 font-bold text-white rounded-full cursor-pointer`};  background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%); &:hover{ transform: scale(0.98); }`}
        onClick={props.restaurantsClicked}
        >Restaurants</div> */}
        </div>
    )
}
